import "./styles.scss";
import { useContext, useEffect } from "react";
import {
  waitForUtagAndTrack,
  createUtagObject,
  addTaggingUtil,
} from "../../helpers/tagging/tagging-utils";
import strings from "../../helpers/tagging/strings";
import {
  getMemberBalanceConfirmation,
  getMemberFirstName,
  getMileagePlanNumber,
} from "../../helpers/getMemberData";
import useProductPageTitle from "../../hooks/useProductPageTitle";
import {
  BasePageTitle,
  MpChoiceProductPageTitle,
  AccountOverviewPath,
} from "../../helpers/constants";
import MPChoiceService from "../../hooks/MPChoiceService";
import { AccountStateType } from "../../state/InitialState";
import UserAccountContext from "../../context/UserAccountContext";
import ChoiceCardContent from "../../helpers/ChoiceCardContent";

const MPThankYouPage = () => {
  const { accountState }: { accountState: AccountStateType } =
    useContext(UserAccountContext);

  let milesAccountState = accountState;
  const milesBenefitId = ChoiceCardContent[2].id.toString();
  
  if (accountState.SelectedChoiceMade.toString() ===  milesBenefitId) {
    let user: { accountState: AccountStateType } = MPChoiceService();
    milesAccountState = user.accountState;
  }

  useProductPageTitle(`${MpChoiceProductPageTitle}${BasePageTitle}`);

  useEffect(() => {
    let { page_name, channel, events } =
      strings.tags.mpchoice_confirmation.load;
    waitForUtagAndTrack(createUtagObject(page_name, channel, events));
    addTaggingUtil();
    window.scrollTo(0, 0);
  }, []);
  
  const selectedChoiceCard = ChoiceCardContent.find(
    (choice) => choice.id.toString() === accountState.SelectedChoiceMade.toString()
  );

  function handleLinkOverview() {
    window.location.href = AccountOverviewPath;
  }

  function getConfirmationAlert() {
    if (accountState.NominatedName !== "") {
      return selectedChoiceCard?.confirmationAlert.replace(
        "nominated-user",
        accountState.NominatedName
      );
    } else {
      return selectedChoiceCard?.confirmationAlert;
    }
  }

  return (
    <div className="thankYouPage">
      <div className="thankYouPage_header">
        <div className="headerNavWrapper">
          <auro-nav>
            <auro-breadcrumb href="https://www.alaskaair.com">
              Home
            </auro-breadcrumb>
            <auro-breadcrumb href="https://www.alaskaair.com/account/overview">
              Account Overview
            </auro-breadcrumb>
            <auro-breadcrumb>100K Choice Benefit</auro-breadcrumb>
          </auro-nav>
        </div>
        <div className="headerContent">
          <div className="choiceIcon">
            <auro-icon
              category={selectedChoiceCard?.cIconCategory}
              name={selectedChoiceCard?.cIconName}
              customSize
              customcolor
              style={{
                width: "5rem",
                color: "var(--auro-color-border-active-on-light)",
                padding: "1rem",
              }}
            ></auro-icon>
          </div>
          <div className="overviewHeading">
            <auro-header
              style={{
                color: "var(--auro-color-brand-midnight-400)",
              }}
            >
              Thank you {getMemberFirstName(accountState)}!
            </auro-header>
          </div>
          <div className="overviewDetails">
            Mileage Plan Number: {getMileagePlanNumber(accountState)}
            <br />
            Available miles:{`&nbpsp;${getMemberBalanceConfirmation(milesAccountState, accountState)}`}
            {getMemberBalanceConfirmation(milesAccountState, accountState)}
          </div>
        </div>
      </div>
      <div className="thankYouPage_body">
        <div className="thankYouAlert">
          <auro-alert type="success">{getConfirmationAlert()}</auro-alert>
        </div>
        <div className="bodyContent display-linebreak">
          {selectedChoiceCard?.confirmationText}
        </div>
        <div className="bodyButtons">
          <auro-button secondary onClick={handleLinkOverview}>
            View Account Overview
          </auro-button>
        </div>
      </div>
    </div>
  );
};

export default MPThankYouPage;
