import "./index.scss";
import { ContentStateType } from "../../../state/InitialState";
import { ContentContext } from "../../../context/ContentContext";
import { useContext, useEffect } from "react";
import { setStyleProperties } from "../../../helpers/setStyleProperties";

const MPSeeAllPerksCta = () => {
  const { contentState }: { contentState: ContentStateType } =
    useContext(ContentContext);

  const seeAllPerks = contentState?.Content[3]?.perksLanding?.seeAllPerks ?? {
    title: "",
    titleColor: "",
    actionUrl: "",
  };

  useEffect(() => {
    // Set CSS variables dynamically
    setStyleProperties([
      {
        name: "--see-all-perk-cta-color",
        value: seeAllPerks.titleColor || "#2e2e2e",
      },
    ]);
  }, [contentState]);

  return (
    <div className="cta-layout">
      <a className={`see-all-perk-cta`} href={seeAllPerks.actionUrl}>
        {seeAllPerks.title}
      </a>
      <auro-icon
        class={`icon-chevron-right`}
        category="interface"
        name="chevron-right"
        customSize
        customColor
        data-testid="icon-element"
      ></auro-icon>
    </div>
  );
};

export default MPSeeAllPerksCta;
