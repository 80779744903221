import { AccountStateType } from "../state/InitialState";
import avisPreferredIcon from "../assets/AVIS_PreferredPlus_H_RGB_web.svg";
import avisPresidentsIcon from "../assets/AVIS_PresidentsClub_H_RGB_web.svg";
import {
  MVP100KTierName,
  MVPGold75KTierName,
  MVPGoldTierName,
  MVPTierName,
  ABGPreferredPlusTierName,
  ABGPresidentsClubTierName,
} from "../helpers/constants";
const numberFormat = new Intl.NumberFormat(undefined, {});

export const getMemberBalance = (accountState: AccountStateType) => {
  if (
    !accountState ||
    !accountState.MileagePlanDetails ||
    !accountState.MileagePlanDetails.MemberBalance
  ) {
    return 0;
  } else {
    return numberFormat.format(
      accountState?.MileagePlanDetails?.MemberBalance || 0
    );
  }
};

export const getMemberBalanceConfirmation = (
  accountState: AccountStateType,
  accountStateOg: AccountStateType
) => {
  return numberFormat.format(
    accountState?.MileagePlanDetails?.MemberBalance ||
      accountStateOg.MileagePlanDetails.MemberBalance
  );
};

export const getMemberFirstName = (accountState: AccountStateType) => {
  if (
    !accountState ||
    !accountState.MileagePlanDetails ||
    !accountState.MileagePlanDetails.FirstName
  ) {
    return "100K member";
  } else {
    return accountState.MileagePlanDetails.FirstName;
  }
};

export const getMileagePlanNumber = (accountState: AccountStateType) => {
  if (
    !accountState ||
    !accountState.MileagePlanDetails ||
    !accountState.MileagePlanDetails.MileagePlanNumber
  ) {
    return "0";
  } else {
    return accountState.MileagePlanDetails.MileagePlanNumber;
  }
};

export const getMemberTierName = (accountState: AccountStateType) => {
  if (
    !accountState ||
    !accountState.MileagePlanDetails ||
    !accountState.MileagePlanDetails.MilestoneName
  ) {
    return "0";
  } else {
    return accountState.MileagePlanDetails.MilestoneName;
  }
};

/*
export const getMemberTierEndDate = (accountState: AccountStateType) => {
  if (
    !accountState ||
    !accountState.MileagePlanDetails ||
    !accountState.MileagePlanDetails.TierEndDate
  ) {
    return "0";
  } else {
    return accountState.MileagePlanDetails.TierEndDate;
  }
};
 */
/*
export const getIsEligible = (accountState: AccountStateType) => {
  const endTier = new Date(accountState.MileagePlanDetails.TierEndDate);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const choiceSelected = accountState.MileagePlanDetails.ChoiceSelectedStatus;

  let isEligible = false;
  if (!choiceSelected) {
    if (
      (currentMonth === 0 && endTier.getFullYear() === currentYear) ||
      (currentMonth >= 1 && endTier.getFullYear() === currentYear + 1)
    ) {
      isEligible = true;
    }
  }
  return isEligible;
};
*/
export const getABGTierName = (accountState: AccountStateType) => {
  const memberTierName = accountState.MileagePlanDetails.MilestoneName;

  if (memberTierName === MVPTierName || memberTierName === MVPGoldTierName) {
    return ABGPreferredPlusTierName;
  } else if (
    memberTierName === MVPGold75KTierName ||
    memberTierName === MVP100KTierName
  ) {
    return ABGPresidentsClubTierName;
  } else {
    return null;
  }
};

export const getABGTierIcon = (accountState: AccountStateType) => {
  const memberABGTierName = getABGTierName(accountState);

  if (memberABGTierName === ABGPreferredPlusTierName) {
    return avisPreferredIcon;
  } else if (memberABGTierName === ABGPresidentsClubTierName) {
    return avisPresidentsIcon;
  }
};
