import MPPerkIcon from "../MPPerkIcon";
import "./index.scss";
import { formatNumber } from "../../../helpers/formatNumber";
import { getIcon } from "../../../helpers/getIcon";
import { ContentStateType } from "../../../state/InitialState";
import { ContentContext } from "../../../context/ContentContext";
import { useContext } from "react";

export interface MPPerkCardProps {
  perkBenefitId: number;
  perkName: string;
  selectionDate?: string;
  achievedAt?: string | number;
}

export default function MPPerkCard(perk: Readonly<MPPerkCardProps>) {
  const { contentState }: { contentState: ContentStateType } =
    useContext(ContentContext);

  const {
    list,
    default: defaultIconName,
    defaultCategory,
  } = contentState?.Content[2]?.icons || {};

  const toTitleCase = (str: string) => {
    return str
      ?.toLowerCase()
      .split(" ")
      .map((title: string) => title.charAt(0).toUpperCase() + title.slice(1))
      .join(" ");
  };

  const formatDate = (dateString: string): string | undefined => {
    if (!dateString) return;
    try {
      // Parse the date string
      const date = new Date(dateString);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        return undefined;
      }

      // Format the date in the desired format
      const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };
      return date.toLocaleDateString("en-GB", options);
    } catch {
      // Handle any unexpected errors
      return undefined;
    }
  };

  return (
    <div className="perk-card">
      <MPPerkIcon
        id={perk.perkBenefitId}
        type={
          getIcon(
            perk.perkBenefitId,
            list || [],
            defaultCategory,
            defaultIconName
          ).name
        }
        category={
          getIcon(
            perk.perkBenefitId,
            list || [],
            defaultCategory,
            defaultIconName
          ).category
        }
      />
      <div className="perk-card-title">{`${toTitleCase(perk.perkName)}`}</div>

      <div className="line-break" />

      <div className="perk-date">
        <div className="perk-text">Selected</div>
        <div className="perk-text">
          {formatDate(perk?.selectionDate as string)}
        </div>
      </div>

      <div className="line-break" />

      <div className="perk-date">
        <div className="perk-text">Achieved at</div>
        <div className="perk-text">{formatNumber(perk?.achievedAt)}</div>
      </div>

      <div className="line-break" />

      <div className="perk-card-link">How to spend miles</div>
    </div>
  );
}
