import { useContext } from "react";
import MPPerkCard from "../MPPerkCard";
import "./index.scss";
import { ContentStateType } from "../../../state/InitialState";
import { ContentContext } from "../../../context/ContentContext";
import { PerksContext } from "../../../context/PerksContext";

export interface PastPerksList {
  perkBenefitId: number;
  perkName: string;
  perkDescription: string;
  isEnabled: boolean;
  milestoneRequiredMiles: number | string;
  selectionDate: string;
}

export default function MPPerkList() {
  const { contentState }: { contentState: ContentStateType } =
    useContext(ContentContext);

  const { perkData } = useContext(PerksContext);

  return (
    <>
      <div className="perk-heading">Perks you have chosen</div>
      <div className="perks-list">
        {perkData.pastPerksList?.map((perk: PastPerksList) => (
          <MPPerkCard
            key={perk.perkBenefitId}
            {...perk}
            achievedAt={perk?.milestoneRequiredMiles}
          />
        ))}
      </div>
    </>
  );
}
