import { useContext, useState } from "react";
import cxs from "cxs";
import PerksTile from "../MPPerksTile";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import { ContentContext } from "../../../context/ContentContext";
import { getIcon } from "../../../helpers/getIcon";
import RoundedButton from "../../RoundedButton/RoundedButton";
import { PerksScreens } from "../../../helpers/constants";
import { IMilestonePerk, IPerkBenefit } from "../../../types/perks";
import "./index.scss";

const MPPerksSelection = () => {
  const { perksState, perksDispatch } = useContext(PerksContext);
  const { selectedMilestonePerk, selectedPerkBenefits, perks } = perksState;
  const { contentState } = useContext(ContentContext);
  const [selectedPerks, setSelectedPerks] = useState<IPerkBenefit[]>(selectedPerkBenefits);
  const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(selectedPerks.length === 0);
  const milestone = perks.availableMilestonePerks.find(
    (milestone) => milestone.milestoneName === selectedMilestonePerk?.milestoneName
  );

  const {
    backgroundColor,
    titleColor,
    title,
    subtitle,
    subtitleColor,
    ctaButtonTitle,
    ctaDisabledBackgroundColor,
    ctaDisabledColor,
    ctaEnabledBackgroundColor,
    ctaEnabledColor,
  } = contentState?.Content[1]?.perkSelection || {};

  const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

  const styles = {
    container: cxs({
      background: `var(--${backgroundColor}, #FBFFF6)`,
    }),
    header: cxs({ color: `var(--${titleColor}, #151515)` }),
    subtitle: cxs({ color: `var(--${subtitleColor}, #151515)` }),
    selectBtn: cxs({
      backgroundColor: isSelectDisabled
        ? `var(--${ctaDisabledBackgroundColor}, #d4d4d4)`
        : `var(--${ctaEnabledBackgroundColor}, #03314f)`,
      color: isSelectDisabled ? `var(--${ctaDisabledColor}, #ffffff)` : `var(--${ctaEnabledColor}, #ffffff)`,
    }),
  };

  const handleSelectButton = () => {
    perksDispatch({
      type: ACTIONS.SET_SELECTED_PERK_BENEFITS,
      value: selectedPerks,
    });
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: PerksScreens.PerksSelectionConfirmation,
    });
  };

  const handleSelectPerks = (id: number) => {
    const selectedPerk = milestone?.perkBenefitsLst.find((perk) => perk.perkBenefitId === id)!;

    setSelectedPerks((previousSelectedPerks) => {
      const found = previousSelectedPerks.find((perk) => perk.perkBenefitId === id);
      let updatedPerks;
      if (!found && previousSelectedPerks.length < (milestone?.numberOfPerksSelectable ?? 0)) {
        updatedPerks = [...previousSelectedPerks, selectedPerk];
      } else {
        updatedPerks = previousSelectedPerks.filter((perk) => perk.perkBenefitId !== id);
      }
      setIsSelectDisabled(updatedPerks.length !== (milestone?.numberOfPerksSelectable ?? 0));
      return updatedPerks;
    });
  };

  const displayPerkTiles = (milestone: IMilestonePerk) => {
    return milestone.perkBenefitsLst.map((perk) => {
      const icon = getIcon(perk.perkBenefitId, list, defaultCategory, defaultIconName);

      return (
        <PerksTile
          id={perk.perkBenefitId}
          key={perk.perkBenefitId}
          selected={selectedPerks.some((selected) => selected.perkBenefitId === perk.perkBenefitId)}
          name={perk.perkName}
          redeemUntilDate="redeem by 10/2023"
          icon={icon.name}
          category={icon.category}
          handleClick={handleSelectPerks}
          variant="outline"
          isButton={true}
        />
      );
    });
  };

  return (
    <div className="perksSelectionPage">
      <div className={`perksSelectionPage__selectionContainer ${styles.container}`}>
        <h2 className={`perksSelectionPage__title ${styles.header}`}>
          {`${title ?? "Select {number}"}`.replace("{number}", `${milestone?.numberOfPerksSelectable ?? 0}`)}
        </h2>
        <p className={`perksSelectionPage__subTitle ${styles.subtitle}`}>{subtitle}</p>
        <div className="perksSelectionPage__tilesContainer">
          <div className="perksSelectionPage__tiles">{milestone && displayPerkTiles(milestone)}</div>
          <RoundedButton
            className={`${isSelectDisabled ? "disabled" : ""} ${styles.selectBtn}`}
            label={ctaButtonTitle ?? "Select"}
            disabled={isSelectDisabled}
            onClick={handleSelectButton}
            type="button"
          />
        </div>
      </div>
    </div>
  );
};

export default MPPerksSelection;
