import axios from "axios";
import { appInsights } from "../services/AppInsights";

const MemberPerksService = async (mpNumber: string) => {
  let memberPerksServiceURL = (process.env.REACT_APP_APIM_BASE_URL +
    "/1/Retain/MileagePlan/mpchoice/api/GetPerks") as string;

  try {
    appInsights.trackEvent({ name: "Member Perks Service called" });
    let response = await axios.get(
      `${memberPerksServiceURL}?mpNumber=${mpNumber}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_MPCHOICE_BFF_KEY,
        },
      }
    );
    return response;
  } catch (ex: any) {
    appInsights.trackException({ exception: ex });
    return ex.response;
  }
};

export default MemberPerksService;
