import "./styles.scss";
import { ClearName, MVPGoldFormPath, MVPGoldName, ThankYouPagePath, MemberBenefitChoice } from "../../helpers/constants";
import UserAccountContext from "../../context/UserAccountContext";
import { useContext, useState } from "react";
import { AccountStateType } from "../../state/InitialState";
import PerksEnrollmentTransaction from "../../hooks/MPPerksService";
import { useNavigate } from "react-router-dom";

interface IProps {
  selectedChoice: any;
  setResultData: (e: boolean) => void;
}


const MPBenefitChoiceButton = (props: IProps) => {
  const { accountState }: { accountState: AccountStateType } = useContext(UserAccountContext);
  const [showLoader, setShowLoader] = useState(false);
  const selectedChoice = `Choose a 100K Benefit`;
  const setErrorOccured = props.setResultData;
  const navigate = useNavigate();
  const actualChoiceSelected = props.selectedChoice;
  const displayError = () => {
    window.scrollTo(-1, -1);
    setErrorOccured(true);
  }
  async function submitMPChoice(e: any) {
    if (props.selectedChoice === MemberBenefitChoice.Nomination) {
      window.location.href = MVPGoldFormPath;
    }
    setShowLoader(true);
    try {
      const transactionResult = await PerksEnrollmentTransaction(
          accountState,
          props.selectedChoice,
          { FirstName: "", LastName: "", MPNumber: "", EmailAddress: "" }
      );
      setShowLoader(false);
      if (!transactionResult) {
        displayError();
        return;
      }
      accountState.SelectedChoiceMade = actualChoiceSelected;
      setErrorOccured(false);
      if (props.selectedChoice === MemberBenefitChoice.Clear) {
        window.location.href = process.env.REACT_APP_REDIRECT_CLEAR as string;
      }
      else {
        navigate(ThankYouPagePath);
      }
    } catch (error) {
      setShowLoader(false);
      displayError();
    }
  }

  return (
      <div className="mp-selection-button">
        <div className="mp-selection-button__grid-child-box">
          {props.selectedChoice ?
              <auro-button onClick={submitMPChoice} loading={showLoader ? 'loading' : null}>{selectedChoice}</auro-button> :
              <auro-button disabled>Choose a 100K Benefit</auro-button>}
        </div>
      </div>
  );
};

export default MPBenefitChoiceButton;