import axios, { AxiosResponse } from "axios";
import { appInsights } from "./AppInsights";

const baseURL = `${process.env.REACT_APP_APIM_BASE_URL}/1/Retain/MileagePlan/mpchoice/api`;

const getMemberV2 = async (token: any): Promise<AxiosResponse<any> | null> => {
  const getPerksURL = `${baseURL}/Member/v2`;

  // Environment validation
  if (!process.env.REACT_APP_MPCHOICE_BFF_KEY || !process.env.REACT_APP_APIM_BASE_URL) {
    const errorMessage = "Required environment variables are missing";
    appInsights.trackException({ exception: new Error(errorMessage) });
    throw new Error(errorMessage);
  }

  try {
    appInsights.trackEvent({
      name: `[Member Service] Get Member/v2 called`,
      properties: { ...token },
    });

    const response = await axios.get<any>(`${getPerksURL}?accountGuid=${token.Guid}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_MPCHOICE_BFF_KEY,
        Authorization: `Bearer ${token.Value}`,
      },
    });

    appInsights.trackEvent({
      name: `[Member Service] Get Member/v2 called`,
      properties: { status: response.status },
    });

    return response;
  } catch (error: any) {
    appInsights.trackException({ exception: error });

    if (axios.isAxiosError(error)) {
      // Handle Axios-specific errors, e.g., network or API issues
      return error.response || null;
    } else {
      // Handle non-Axios errors
      throw new Error("An unexpected error occurred");
    }
  }
};

export { getMemberV2 };
