import { useEffect } from 'react';
import './index.scss'
import { setStyleProperties } from '../../../helpers/setStyleProperties';

const MilestoneBadge: React.FC<{
    title: string;
    backgroundColor?: string;
    textColor?: string;
    subtractColor?: string;
}> = (
    props
) => {
        useEffect(() => {
            setStyleProperties([
                {
                    name: "--badge-background-color",
                    value: !!props.backgroundColor ? `var(--${props.backgroundColor})` : `#FFFFFF`
                },
                {
                    name: "--badge-color",
                    value: !!props.textColor ? `var(--${props.textColor})` : `#2A2A2A`
                },
                {
                    name: "--badge-subtract-color",
                    value: !!props.subtractColor ? `var(--${props.subtractColor})` : `#FFFFFF`
                },
            ]);
        });

        return (
            <div className="milestone-badge-container">
                {!!props.subtractColor &&
                    <span className='milestone-subtract-container'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="24"
                            viewBox="0 0 16 24"
                            fill="none"
                        >
                            <path data-testid="milestone-subtract"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.9912 0C11.298 1.96124 8 6.59548 8 12C8 17.4045 11.298 22.0388 15.9912 24H12C5.37259 24 0 18.6274 0 12C0 5.37258 5.37259 0 12 0H15.9912Z"
                                className="milestone-subtract"
                            />
                        </svg>
                    </span>
                }
                <span data-testid="milestone-badge" className={`milestone-badge ${!props.subtractColor ? "-bordered" : "-offset-negative-6"}`}
                    dangerouslySetInnerHTML={{ __html: props.title }}>
                </span>
            </div>
        )
    }

export default MilestoneBadge