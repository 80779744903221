import { PerksScreens } from "../helpers/constants";
import { IMilestonePerk, IPerkBenefit, IPerksResponse } from "../types/perks";

export type initialStateType = {
  activeScreen: string;
  selectedMilestonePerk: null | IMilestonePerk;
  selectedPerkBenefits: IPerkBenefit[];
  perks: IPerksResponse;
};

export const initialState = {
  activeScreen: PerksScreens.Hub, // Default screen is Perks Hub
  selectedMilestonePerk: null,
  selectedPerkBenefits: [],
  perks: {
    currentMiles: "",
    pastPerksList: [],
    availableMilestonePerks: [],
    nextPerks: {
      milestoneRequiredMiles: 0,
      milestoneName: "",
      numberOfPerksSelectable: 0,
      perkBenefitsLst: [],
    },
    errorMessage: "",
    errorCode: "",
  },
};
