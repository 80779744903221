import { useContext } from "react";
import cxs from "cxs";
import WindowIcon from "../../../WindowIcon";
import { ACTIONS, PerksContext } from "../../../../context/PerksContext";
import { PerksScreens } from "../../../../helpers/constants";
import { ContentContext } from "../../../../context/ContentContext";
import { IMilestonePerk } from "../../../../types/perks";
import "./styles.scss";

const MPSelectPerksCTA = (props: { milestone: IMilestonePerk }) => {
  const { perksDispatch } = useContext(PerksContext);
  const { contentState } = useContext(ContentContext);

  const { milestoneName, numberOfPerksSelectable } = props.milestone;

  const {
    singlePerkTitle,
    multiPerksTitle,
    multiPerksSubTitle,
    singlePerkSubTitle,
    backgroundColor,
    titleColor,
    subTitleColor,
    leftIconColor,
    leftIconForegroundColor,
    ctaIcon,
    ctaIconCategory,
    ctaIconColor,
    leftIcon,
    leftIconCategory,
  } = contentState?.Content[0]?.selectPerks?.cta || {};

  const isMultiplePerks = numberOfPerksSelectable > 1;
  const title = isMultiplePerks ? multiPerksTitle : singlePerkTitle;
  let subtitle = isMultiplePerks ? multiPerksSubTitle : singlePerkSubTitle;
  subtitle = subtitle?.replace("{milestone}", milestoneName);

  const styles = {
    ctaContainer: cxs({
      backgroundColor: `var(--${backgroundColor}, #ffffff)`,
    }),
    title: cxs({ color: `var(--${titleColor}, #151515)` }),
    subtitle: cxs({ color: `var(--${subTitleColor}, #151515)` }),
    iconContainer: cxs({
      color: `var(--${leftIconColor}, #03314F)`,
      backgroundColor: `var(--${leftIconForegroundColor}, #2CD7E1)`,
      borderColor: `var(--${leftIconForegroundColor}, #2CD7E1)`,
    }),
  };

  const actionIconStyles = {
    color: `var(--${ctaIconColor}, #2E2E2E)`,
  };

  const handleClick = () => {
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: PerksScreens.PerksSelection,
    });
    perksDispatch({
      type: ACTIONS.SET_SELECTED_MILESTONE_PERK,
      value: props.milestone,
    });
  };

  return (
    <div onClick={handleClick} className={`ctaCard__container ${styles.ctaContainer}`}>
      <div className="ctaCard__leftInfo">
        <WindowIcon
          category={leftIconCategory ?? ""}
          icon={leftIcon ?? ""}
          iconContainerClassName={styles.iconContainer}
          variant="solid"
        />
        <div className="ctaCard_textContainer">
          <div className={`ctaCard__title ${styles.title}`}>{title}</div>
          <p className={`ctaCard__description ${styles.subtitle}`}>{subtitle}</p>
        </div>
      </div>
      <div className="ctaCard__rightAction">
        <auro-icon style={actionIconStyles} name={ctaIcon} category={ctaIconCategory} customColor customSize />
      </div>
    </div>
  );
};

export default MPSelectPerksCTA;
