import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { IPerksResponse, IPerkBenefit, IMilestonePerk } from "../types/perks";
import { getPerks } from "../services/PerksService";
import UserAccountContext from "./UserAccountContext";
import { AccountStateType, initialPerkDataState, PerkDataStateType } from "../state/InitialState";
import { initialStateType, initialState } from "../state/perksState";

type PerksContextType = {
  perksState: initialStateType;
  perksDispatch: Dispatch<ActionType>;
  perkData: PerkDataStateType;
  setPerkData: Dispatch<SetStateAction<PerkDataStateType>>;
};

export const PerksContext = createContext<PerksContextType>({
  perksState: initialState,
  perksDispatch: () => {},
  perkData: initialPerkDataState,
  setPerkData: () => {},
});

export const ACTIONS = {
  SET_ACTIVE_SCREEN: "SET_ACTIVE_SCREEN",
  SET_SELECTED_MILESTONE_PERK: "SET_SELECTED_MILESTONE_PERK",
  SET_SELECTED_PERK_BENEFITS: "SET_SELECTED_PERK_BENEFITS",
  SET_PERKS: "SET_PERKS",
};

type ActionType = {
  type: string;
  value: string | IPerkBenefit[] | IPerksResponse | IMilestonePerk;
};

const perksReducer = (state: initialStateType, action: ActionType): initialStateType => {
  switch (action.type) {
    case ACTIONS.SET_ACTIVE_SCREEN:
      return {
        ...state,
        activeScreen: action.value as string,
      };
    case ACTIONS.SET_SELECTED_MILESTONE_PERK:
      return {
        ...state,
        selectedMilestonePerk: action.value as IMilestonePerk,
      };
    case ACTIONS.SET_SELECTED_PERK_BENEFITS:
      return {
        ...state,
        selectedPerkBenefits: action.value as IPerkBenefit[],
      };
    case ACTIONS.SET_PERKS:
      return {
        ...state,
        perks: action.value as IPerksResponse,
      };
    default:
      return state;
  }
};

export const PerksProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { accountState }: { accountState: AccountStateType } = useContext(UserAccountContext);
  const [perksState, perksDispatch] = useReducer(perksReducer, initialState);
  const [perkData, setPerkData] = useState(initialPerkDataState); // please use perks data from perksState moving forward

  useEffect(() => {
    const getPerksData = async () => {
      try {
        const res = await getPerks(accountState.MileagePlanDetails.MileagePlanNumber);
        if (res !== null) {
          perksDispatch({ type: ACTIONS.SET_PERKS, value: res.data });
        }
      } catch (error) {
        console.error("Error fetching perks data:", error);
      }
    };
    getPerksData();
  }, [accountState.MileagePlanDetails.MileagePlanNumber]);

  const contextValue = {
    perksDispatch,
    perksState,
    perkData,
    setPerkData,
  };

  return <PerksContext.Provider value={contextValue}>{children}</PerksContext.Provider>;
};
