export const AsBaseUrl = "https://www.alaskaair.com";
export const MVPGoldName = "Gift a MVP Gold status";
export const ClearName = "Complimentary CLEAR® Plus membership";
export const MVPGoldFormPath = "/account/mpchoice/mvp-gold-nomination";
export const MP100KPath = "/account/mpchoice";
export const ABGFormPath = "/account/avis-status-match";
export const ABGConfirmationPath = "/account/avis-status-match/confirmation";
export const RegularTierName = "Regular";
export const MVPTierName = "MVP";
export const MVPGoldTierName = "MVP Gold";
export const MVPGold75KTierName = "MVP Gold 75K";
export const MVP100KTierName = "100K";
export const ThankYouPagePath = "/account/mpchoice/confirmation";
export const ABGPreferredPlusTierName = "Preferred Plus";
export const ABGPresidentsClubTierName = "President's Club";
export const Perks = "/account/perks";
export const ASLoginUrl =
  "https://www.alaskaair.com/www2/ssl/myalaskaair/MyAlaskaAir.aspx?CurrentForm=UCSignInStart&url=https://www.alaskaair.com";
export const ASPageNotFoundUrl = "https://www.alaskaair.com/content/page-not-found";
export const MemberBenefitChoice = {
  Lounge: 1,
  Wifi: 2,
  Miles: 3,
  Nomination: 4,
  Clear: 5,
};
export const AccountOverviewPath = "https://www.alaskaair.com/account/overview";
export const AccountMpChoicePath = "https://www.alaskaair.com/account/mpchoice";
export const MPChoiceProductName = "mp-choice";
export const AvisStatusMatchProductName = "avis-status-match";
export const AvisForgotNameLink =
  "https://www.avis.com/en/loyalty-profile/avis-preferred/forgot-username";
export const BasePageTitle = "Alaska Airlines";
export const AvisProductPageTitle = "Avis Car Rental Status Match - ";
export const MpChoiceProductPageTitle = "Mileage Plan 100K Benefit Choices - ";

export const MemberInvalidTierPromotions: string[] = [
  "tier match - dl",
  "tier match - dl 2023",
  "tier match - dl 2024",
  "nomination",
  "mm companion",
];

export const PerksScreens = {
  Hub: "hub",
  PerksSelection: "perksSelection",
  PerksSelectionConfirmation: "perksSelectionConfirmation",
};
