export const initialContentState = {
  Content: [
    {
      selectPerks: {
        title: "Select perks",
        titleColor: "Gray-gray-headline-900",
        cta: {
          backgroundColor: "Gray-white",
          singlePerkTitle: "select a perk",
          multiPerksTitle: "select 2 perks",
          titleColor: "Gray-gray-headline-900",
          multiPerksSubTitle:
            "You earned perks for reaching {milestone}! Select them now!",
          singlePerkSubTitle:
            "You earned a perk for reaching {milestone}! Select them now!",
          subTitleColor: "Gray-gray-headline-900",
          leftIcon: "sparkle-stroke",
          leftIconCategory: "interface",
          leftIconColor: "ds-color-icon-ui-default-default",
          leftIconShadowColor: "ds-text-primary-default",
          leftIconForegroundColor:
            "ds-color-tier-alaska-primary-member-default",
          ctaIcon: "chevron-right",
          ctaIconCategory: "interface",
          ctaIconColor: "Gray-gray-headline-800",
          actionUrl: "#",
        },
        backgroundColor: "gold-100",
      },
    },
    {
      perkSelection: {
        backgroundColor: "ds-color-background-secondary-100-default",
        ctaButtonTitle: "Select",
        ctaDisabledBackgroundColor: "ds-color-excursion-grey-200",
        ctaDisabledColor: "ds-text-primary-inverse",
        ctaEnabledBackgroundColor: "ds-color-excursion-blue-1000",
        ctaEnabledColor: "ds-text-primary-inverse",
        title: "Select {number}",
        titleColor: "Gray-gray-headline-900",
        subtitle: "You will be able to confirm your choice in the next step.",
        subtitleColor: "Gray-gray-headline-900",
        header: {
          backgroundColor: "ds-color-excursion-yellow-400",
          ctaColor: "ds-text-primary-default",
          subtitle:
            "You've successfully unlocked perks for reaching the {milestone} milestone! Make your selection below.",
          subtitleColor: "ds-text-primary-default",
          title: "select your {count} {perks}",
          titleColor: "ds-text-primary-default",
          cta: {
            href: "#",
            title: "learn more about perks",
          },
        },
        confirmSelection: {
          backgroundColor: "ds-color-background-secondary-100-default",
          title: "Confirm Selection?",
          titleColor: "Gray-gray-headline-900",
          subtitle:
            "Once you select your perks, this action can't be undone and your choice will be final",
          subtitleColor: "Gray-gray-headline-900",
          confirmButtonTitle: "Confirm",
          confirmButtonBackgroundColor:
            "ds-color-container-ui-primary-default-default",
          cancelButtonTitle: "Choose a different perk",
          cancelButtonBackgroundColor: "ds-text-primary-default",
        },
        congratulationsSelection: {
          backgroundColor: "ds-text-primary-default",
          title: "Congrats!",
          titleColor: "ds-text-primary-default",
          singlePerkText:
            "You've successfully selected your {milestone} Milestone perk",
          singlePerkColor: "ds-text-primary-default",
          multiplePerkColor: "ds-text-primary-default",
          ctaButtonText: "Return to Perks",
          ctaButtonBackgroundColor: "ds-color-excursion-blue-1000",
          ctaButtonHref: "#",
        },
        top: {
          backgroundColor: "ds-color-excursion-yellow-200",
          leftIcon: "chevron-left",
          leftIconCategory: "interface",
          leftIconColor: "ds-text-primary-default",
          rightIcon: "x-sm",
          rightIconCategory: "interface",
          rightIconColor: "ds-text-primary-default",
          title: "Mileage Plan™",
          titleColor: "ds-text-primary-default",
        },
        perk: {
          perkSelected: {
            borderColor: "ds-color-tier-alaska-primary-member-default",
            iconBackgroundColor: "ds-color-tier-alaska-primary-member-default",
            iconBorderColor: "ds-color-excursion-blue-1000",
            iconColor: "ds-color-icon-ui-default-default",
            subtitleColor: "Gray-gray-headline-900",
            titleColor: "Gray-gray-headline-800",
          },
          perkUnselected: {
            borderColor: "ds-color-border-ui-default-default",
            iconBackgroundColor: "ds-text-primary-default",
            iconBorderColor: "ds-color-excursion-blue-1000",
            iconColor: "ds-color-icon-ui-default-default",
            subtitleColor: "Gray-gray-headline-900",
            titleColor: "Gray-gray-headline-800",
          },
        },
      },
    },
    {
      icons: {
        list: [
          {
            perkId: "1",
            name: "food-and-drink",
            category: "in-flight",
          },
          {
            perkId: "2",
            name: "wifi",
            category: "in-flight",
          },
          {
            perkId: "3",
            name: "sparkle-stroke",
            category: "interface",
          },
          {
            perkId: "4",
            name: "elite-stroke",
            category: "in-flight",
          },
          {
            perkId: "5",
            name: "clear",
            category: "partnership",
          },
        ],
        default: "sparkle-stroke",
        defaultCategory: "interface",
      },
    },
    {
      perksLanding: {
        title: "Perks",
        titleColor: "ds-text-primary-default",
        backgroundColor: "gold-100",
        welcomeMessage:
          "As part of the new Alaska loyalty program, when you achieve a new milestone you'll be able to come here to select from a choice of perks.",
        welcomeMessageColor: "ds-text-primary-default",
        availablePerks: "Available to use",
        seeAllPerks: {
          title: "View complete list of perks",
          titleColor: "ds-text-primary-default",
          actionUrl:
            "https://www.alaskaair.com/content/mileage-plan/membership-benefits?lid=mileageplan:mileage-plan-overview:membership-benefits",
        },
        milestoneSummary: {
          currentMilestoneDetails: {
            milesProgressMessage: "{m} / {tm} elite-qualifying miles",
            nextPerkMilestoneMessage: "to {name} perks milestone",
          },
        },
      },
    },
    {
      perks: [
        {
          id: 0,
          perkSummary: "",
        },
      ],
    },
    {
      milestones: [
        {
          id: 0,
          name: "",
          milestoneSummary: "",
          chips: {
            backgroundColor: "",
            subtractColor: "",
            textColor: "",
            title: "",
          },
        },
      ],
    },
  ],
  Error: { message: null },
};

const initialState = {
  Token: {
    Value: "",
    Guid: "",
  },
  MVPGoldNominationDetails: {
    FirstName: "",
    LastName: "",
    MileagePlanNumber: "",
    Email: "",
  },
  MileagePlanDetails: {
    MileagePlanNumber: "",
    FirstName: "",
    LastName: "",
    miles: "",
    TierName: "",
    MemberSelectionId: 0,
    MilestoneId: 0,
    MilestoneName: "",
    Email: "",
    TierEndDate: "",
    ChoiceSelectedStatus: "",
    HowTier: "",
    milestoneId: 0,
    MP100KChoice: "",
    MemberBalance: 0,
    MilestoneAchievmentDate: "",
    SelectionExpirationDate: "",
    IsEligibleFor100KChoiceSelection: false,
    Perks: [
      {
        PerkBenefitId: null,
        PerkName: "",
        PerkDescription: "",
        isEnabled: null,
      },
    ],
  },
  isSuperUser: false,
  Error: { message: null },
  SelectedChoiceMade: "",
  NominatedName: "",
};

export const initialPerkDataState = {
  currentMiles: null,
  upcomingMilestones: {
    nextMilestone: "150K",
    nextMilestoneMile: 150000,
    futureMilestone: "200K",
    futureMilestoneMile: 200000,
  },
  pastPerksList: [],
  availableMilestonePerks: [
    {
      milestoneName: "30K",
      numberOfPerksSelectable: 1,
      milestoneRequiredMiles: 30000,
      memberSelectionLst: [
        {
          memberSelectionId: 3140,
          mpNumber: "880937676",
          milestoneId: 2,
          perkBenefitId: null,
          selectionDate: null,
          selectionExpirationDate: "2025-01-31T00:00:00",
          fulfillmentDate: null,
          milestoneAchievmentDate: "2024-09-04T23:25:45.69",
          milestoneAchievmentId: "333e7a4c-9cbf-4e4d-9068-e2ec5b0bb8b7",
        },
      ],
      perkBenefitsLst: [
        {
          perkBenefitId: 1,
          perkName: "100K CHOICE BENEFIT - ALASKA LOUNGE+",
          perkDescription: "100K Choice Lounge Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 4,
          perkName: "100K CHOICE BENEFIT - MVP GOLD NOMINATION",
          perkDescription: "100K Choice Gold Nom Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 5,
          perkName: "100K CHOICE BENEFIT - CLEAR",
          perkDescription: "100K Choice Clear Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 6,
          perkName: "$25 DISCOUNT CODE",
          perkDescription: "$25 DISCOUNT CODE Flag",
          isEnabled: true,
        },
      ],
    },
    {
      milestoneName: "100K",
      numberOfPerksSelectable: 1,
      milestoneRequiredMiles: 100000,
      memberSelectionLst: [
        {
          memberSelectionId: 3137,
          mpNumber: "880937676",
          milestoneId: 5,
          perkBenefitId: null,
          selectionDate: null,
          selectionExpirationDate: "2025-08-29T18:21:21.9",
          fulfillmentDate: null,
          milestoneAchievmentDate: "2024-08-29T18:21:21.9",
          milestoneAchievmentId: "521f7a48-1985-4f90-a6e3-e2e473fbebb9",
        },
      ],
      perkBenefitsLst: [
        {
          perkBenefitId: 1,
          perkName: "100K CHOICE BENEFIT - ALASKA LOUNGE+",
          perkDescription: "100K Choice Lounge Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 2,
          perkName: "100K CHOICE BENEFIT - WIFI",
          perkDescription: "100K Choice Wifi Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 3,
          perkName: "100K CHOICE BENEFIT - 50K BONUS MILES",
          perkDescription: "100K Choice 50K Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 4,
          perkName: "100K CHOICE BENEFIT - MVP GOLD NOMINATION",
          perkDescription: "100K Choice Gold Nom Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 5,
          perkName: "100K CHOICE BENEFIT - CLEAR",
          perkDescription: "100K Choice Clear Flag",
          isEnabled: true,
        },
      ],
    },
  ],
  nextPerks: {
    milestoneId: 6,
    milestoneRequiredMiles: 150000,
    milestoneName: "150K",
    numberOfPerksSelectable: 2,
    perkBenefitsLst: [],
  },
  futurePerks: {
    milestoneId: 7,
    milestoneRequiredMiles: 200000,
    milestoneName: "200K",
    numberOfPerksSelectable: 2,
    perkBenefitsLst: [],
  },
  errorMessage: null,
  errorCode: null,
};

export type AccountStateType = typeof initialState;
export type ContentStateType = typeof initialContentState;
export type PerkDataStateType = typeof initialPerkDataState;

export default initialState;
