import "./index.scss";
import MPPerkList from "../MPPerkList";
import SelectPerksModule from "../MPSelectPerks/MPSelectPerksContainer";
import { useContext, useEffect } from "react";
import {
  AccountStateType,
  ContentStateType,
} from "../../../state/InitialState";
import { ContentContext } from "../../../context/ContentContext";
import cxs from "cxs";
import MPMilestoneContainer from "../MPMilestoneContainer";
import { isFromMobileWebView } from "../../../services/header-footer";
import { PerksContext } from "../../../context/PerksContext";
import MemberPerksService from "../../../hooks/MemberPerksService";
import UserAccountContext from "../../../context/UserAccountContext";

const MPPerksContainer = () => {
  const { contentState }: { contentState: ContentStateType } =
    useContext(ContentContext);
  const { accountState }: { accountState: AccountStateType } =
    useContext(UserAccountContext);

  const { setPerkData } = useContext(PerksContext);

  const title = contentState?.Content[3]?.perksLanding?.title ?? "Perks";
  const introductionMessage =
    contentState?.Content[3]?.perksLanding?.welcomeMessage ??
    "As part of the new Alaska loyalty program, when you achieve a new milestone you'll be able to come here to select from a choice of perks.";

  const styles = {
    perkPageIntroduction: cxs({
      color: `var(--${contentState?.Content[3]?.perksLanding?.welcomeMessageColor}, var(--${contentState?.Content[3]?.perksLanding?.welcomeMessageColor}, #2e2e2e))`,
    }),
    perkPageTitle: cxs({
      color: `var(--${contentState?.Content[3]?.perksLanding?.titleColor}, var(--${contentState?.Content[3]?.perksLanding?.titleColor}, #2e2e2e))`,
    }),
    overviewContainer: cxs({
      paddingTop: isFromMobileWebView() ? `81px` : `none`,
    }),
  };

  useEffect(() => {
    const fetchPerksData = async () => {
      const response = await MemberPerksService(
        accountState.MileagePlanDetails.MileagePlanNumber
      );

      if (response) {
        setPerkData(response.data);
      }
    };
    fetchPerksData();
  }, []);

  return (
    <div className={`overviewPerksContainer ${styles.overviewContainer}`}>
      <div className="App container">
        <section className="mPPerksGuestOverview">
          <div className="mPPerksGuestOverview_content">
            <div className="mPPerksWrapper">
              <div className="mPPerksBody">
                <div className="mPPerksHeading">
                  <auro-header level="1" display="600">
                    {title}
                  </auro-header>
                  <p
                    className={`perk-page-introduction ${styles.perkPageIntroduction}`}
                  >
                    {introductionMessage}
                  </p>
                </div>
                <div className="mPPerksDetails">
                  <MPMilestoneContainer />
                  <div className="mPSelectedPerks">
                    <SelectPerksModule />
                    <MPPerkList />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MPPerksContainer;
