import React from "react";
import "./index.scss";
import PerksBenefitItem, { PerkBenefitItemProps } from "./PerksBenefitItem";
import MilestoneBadge, { MilestoneBadgeType } from "./MilestoneBadge";

export interface ChipDetailProps {
  textColor: string
  backgroundColor: string
  subtractColor: string
  title: string
}

export interface FuturePerksDetailProps {
  info: string
  perkBenefitsLst: PerkBenefitItemProps[]
  chip: ChipDetailProps | undefined
}

const MPFuturePerksDetail: React.FC<FuturePerksDetailProps> = (props) => {
  return (
    <div className="future-perks-container">
      <MilestoneBadge
        textColor={props.chip?.textColor ?? ""}
        backgroundColor={props.chip?.backgroundColor ?? ""}
        offsetColor={props.chip?.subtractColor ?? "" }
        title={props.chip?.title ?? ""}
        type={props.chip?.subtractColor === "" ? MilestoneBadgeType.Normal : MilestoneBadgeType.Colored }
      />
      <p className="info">{props.info}</p>
      <div className="benefit-list">
        {props.perkBenefitsLst.map(
          (benefit: PerkBenefitItemProps, index: number) => (
            <PerksBenefitItem key={index} {...benefit} />
          )
        )}
      </div>
    </div>
  )
}

export default MPFuturePerksDetail;
