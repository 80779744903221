/**
 * Replaces multiple dynamic placeholders in a string with their respective values.
 *
 * @param input - The string containing the placeholders.
 * @param replacements - An object where keys are placeholders (without curly braces) and values are the replacement strings.
 * @returns A new string with all placeholders replaced by their corresponding values.
 */
export const replacePlaceholderName = (
  input: string,
  replacements: { [key: string]: string }
): string => {
  const keyLength = Object.keys(replacements).length;

  // Iterate over each placeholder and replace it with the corresponding value
  const result = Object.keys(replacements).reduce((current, key, index) => {
    const regex = new RegExp(`\\{${key}\\}`, "g");

    return current.replace(regex, replacements[key]);
  }, input);

  return result;
};
