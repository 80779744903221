import React, { useContext } from "react";
import "./index.scss";
import { formatNumber } from "../../helpers/formatNumber";
import { AccountStateType, ContentStateType } from "../../state/InitialState";
import { ContentContext } from "../../context/ContentContext";
import { replacePlaceholderName } from "../../helpers/replacePlaceholderName";
import { PerksContext } from "../../context/PerksContext";
import UserAccountContext from "../../context/UserAccountContext";

const MPPerkProgressMilestone = () => {
  const { contentState }: { contentState: ContentStateType } =
    useContext(ContentContext);
  const { accountState }: { accountState: AccountStateType } =
    useContext(UserAccountContext);
  const {
    nextPerkMilestoneMessage: toNextPerkMilestoneMessage,
    milesProgressMessage,
  } =
    contentState?.Content[3]?.perksLanding?.milestoneSummary
      ?.currentMilestoneDetails || {};
  const { perkData } = useContext(PerksContext);
  const { upcomingMilestones } = perkData;
  const currentMilestoneMile = Number(accountState?.MileagePlanDetails?.miles);

  const currentPercentageStatus =
    (Number(accountState?.MileagePlanDetails?.miles) /
      upcomingMilestones.nextMilestoneMile) *
    100;

  // Calculate the progress percentage based on the current progress and final step
  const progressPercentage =
    upcomingMilestones.nextMilestoneMile > currentMilestoneMile
      ? (currentMilestoneMile / upcomingMilestones.nextMilestoneMile) * 98 -
        38
      : (currentMilestoneMile / upcomingMilestones.futureMilestoneMile) * 96;

  // Define the SVG width in pixels (100% width in parent container)
  const svgWidth = 1000; // Adjust this if the parent container has a different width

  // Calculate the positions of the upcoming steps
  const lastStepXPosPx = svgWidth; // 30px away from the end of the line
  const lastStepXPosPercentage = (lastStepXPosPx / svgWidth) * 96;

  // Calculate the center position for the next step
  const nextStepXPosPercentage =
    currentMilestoneMile > upcomingMilestones.nextMilestoneMile ? 98 : 60;

  // Function to format labels as "10k", "20k", etc.
  const formatLabel = (value: number | undefined) => {
    return value ? `${value / 1000}k` : "";
  };

  return (
    <div>
      <div className="perks-percentage-status-value">
        {Math.round(currentPercentageStatus)}
        <span>%</span>
      </div>
      <div className="perks-percentage-status-text">
        {replacePlaceholderName(toNextPerkMilestoneMessage || "", {
          name: formatLabel(upcomingMilestones.nextMilestoneMile),
        })}
      </div>
      <svg width="100%" height="50">
        {/* Background line */}
        <line
          x1="0"
          y1="25"
          x2="96%"
          y2="25"
          stroke="var(--Blue-blue-background-800, #03314F)"
          strokeWidth="2"
          strokeLinecap="round" // Adds rounded edges to the background line
        />
        {/* Progress line */}
        <line
          x1="5"
          y1="25"
          x2={`${Math.floor(progressPercentage)}%`}
          y2="25"
          stroke="var(--ds-color-excursion-blue-500, #2CD7E1)"
          strokeWidth="10"
          strokeLinecap="round" // Adds rounded edges to the progress line
        />

        {/* Render the next step and the final step */}

        {[
          upcomingMilestones.nextMilestoneMile,
          upcomingMilestones.futureMilestoneMile,
        ].map((step, index) => {
          const labelValue = step;

          // Determine the position for each step
          let xPos;
          if (
            step === upcomingMilestones.nextMilestoneMile &&
            step !== upcomingMilestones.futureMilestoneMile
          ) {
            xPos = nextStepXPosPercentage;
          } else {
            xPos = lastStepXPosPercentage;
          }

          // Skip rendering the line and label for steps that are already passed
          if (
            (step === upcomingMilestones.nextMilestoneMile &&
              xPos <= progressPercentage) ||
            (step === upcomingMilestones.futureMilestoneMile &&
              xPos === progressPercentage)
          )
            return null;

          return (
            <React.Fragment key={index}>
              {/* Step line */}
              <line
                x1={`${xPos}%`}
                y1="20"
                x2={`${xPos}%`}
                y2="30"
                stroke="#03314F"
                strokeWidth="4"
                strokeLinecap="round" // Adds rounded edges to the step lines
              />
              {/* Step label */}
              {xPos >= progressPercentage && (
                <text
                  x={`${
                    xPos -
                    (upcomingMilestones.nextMilestoneMile ===
                    upcomingMilestones.futureMilestoneMile
                      ? 2
                      : 1)
                  }%`}
                  y="45"
                  fontSize="12"
                  textAnchor="middle"
                  fill="var(--Gray-gray-headline-900, #151515)"
                >
                  {formatLabel(labelValue)}
                </text>
              )}
            </React.Fragment>
          );
        })}
      </svg>
      <div className="progress-miles-status-text">
        {replacePlaceholderName(milesProgressMessage ?? "", {
          m: formatNumber(currentMilestoneMile) ?? "0",
          tm: formatNumber(upcomingMilestones.nextMilestoneMile) ?? "0",
        })}
      </div>
    </div>
  );
};

export default MPPerkProgressMilestone;
