import React, { useContext } from "react";
import "./index.scss";
import { getIcon } from "../../../../../helpers/getIcon";
import { ContentStateType } from "../../../../../state/InitialState";
import { ContentContext } from "../../../../../context/ContentContext";

export interface PerkBenefitItemProps {
  name: string;
  iconId: number;
}

const PerksBenefitItem: React.FC<PerkBenefitItemProps> = (props) => {
  const { contentState }: { contentState: ContentStateType } =
    useContext(ContentContext);
  const {
    list,
    default: defaultIconName,
    defaultCategory,
  } = contentState?.Content[2]?.icons || {};

  return (
    <>
      <div className="benefit-item-container" data-testid="perk-benefit-item">
        <auro-icon
          class="icon"
          category={
            getIcon(
              Number(props.iconId.toString() ?? ""),
              list || [],
              defaultCategory,
              defaultIconName
            ).name
          }
          name={
            getIcon(
              Number(props.iconId.toString() ?? ""),
              list || [],
              defaultCategory,
              defaultIconName
            ).name
          }
          customSize
          customColor
        />
        <div className="name">{props.name}</div>
      </div>
    </>
  );
};

export default PerksBenefitItem;
