import "./index.scss";
import MPPerkProgressMilestone from "../../MPPerkProgressMilestone";
import MPSeeAllPerksCta from "../MPSeeAllPerksCta";
import MPFuturePerksDetail from "../MPPerksContainer/MPFuturePerksDetail";
import { ContentStateType } from "../../../state/InitialState";
import { useContext, useEffect, useState } from "react";
import { PerksContext } from "../../../context/PerksContext";
import { ContentContext } from "../../../context/ContentContext";
import MilestoneBadge from "../MilestoneBadge";
import UserAccountContext from "../../../context/UserAccountContext";
import { getMemberV2 } from "../../../services/MemberService";

const MPMilestoneContainer = () => {
  const { contentState }: { contentState: ContentStateType } =
    useContext(ContentContext);
  const { perkData } = useContext(PerksContext);
  const { accountState } = useContext(UserAccountContext);
  const [currentMilestoneState, setCurrentMilestoneState] = useState<any | undefined>(undefined);

  const getMilestone = (milestoneId: number) => {
    const milestone = contentState?.Content[5]?.milestones?.find(
      (milestone) => milestone.id === milestoneId
    );
    return milestone
  }

  const getPerk = (perkId: number) => {
    const perk = contentState?.Content[4]?.perks?.find(
      (perk) => perk.id === perkId
    );
    return perk
  }

  useEffect(() => {
    getMemberV2(accountState.Token).then((response) => {
      if (response) {
        console.log(response.data.milestoneId);
        const milestone = getMilestone(response.data.milestoneId);
        setCurrentMilestoneState(milestone);
      }
    });
  }, [contentState]);

  return (
    <>
      {currentMilestoneState &&
        <div className="current-milestone-container">
          <div className="current-badge-container">
            <MilestoneBadge
              title={currentMilestoneState?.chips.title}
              backgroundColor={currentMilestoneState?.chips.backgroundColor}
              textColor={currentMilestoneState?.chips.textColor}
              subtractColor={currentMilestoneState?.chips.subtractColor}
            />
          </div>
        </div>
      }
      <div className="milestone-container">
        <MPPerkProgressMilestone />
        <MPFuturePerksDetail
          chip={getMilestone(perkData.nextPerks.milestoneId)?.chips}
          info={
            getMilestone(perkData.nextPerks.milestoneId)?.milestoneSummary ?? ""
          }
          perkBenefitsLst={(
            perkData.nextPerks.perkBenefitsLst.map((perk: { perkBenefitId: number }) => {
              const fetchedPerk = getPerk(perk.perkBenefitId)
              return {
                name: fetchedPerk?.perkSummary ?? "",
                iconId: fetchedPerk?.id ?? 0,
              }
            }
            ))}
        />

        <MPFuturePerksDetail
          chip={getMilestone(perkData.futurePerks.milestoneId)?.chips}
          info={
            getMilestone(perkData.futurePerks.milestoneId)?.milestoneSummary ?? ""
          }
          perkBenefitsLst={(
            perkData.futurePerks.perkBenefitsLst.map((perk: { perkBenefitId: number }) => {
              const fetchedPerk = getPerk(perk.perkBenefitId)
              return {
                name: fetchedPerk?.perkSummary ?? "",
                iconId: fetchedPerk?.id ?? 0,
              }
            }
            ))}
        />
        <div className="divider"></div>
        <MPSeeAllPerksCta />
      </div>
    </>
  );
};

export default MPMilestoneContainer;
