import "./style.css";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { OptimizelyProvider, createInstance } from "@optimizely/react-sdk";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import MileagePlanData from "./hooks/MPChoiceService";
import { mockedServer } from "./server";

if (process.env.REACT_APP_RELEASE_ENV === "local") {
  console.log("Starting fake APIs");
  mockedServer();
}

// Instantiate an Optimizely client
const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
});

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <OptimizelyProvider
      optimizely={optimizely}
      user={{ id: process.env.REACT_APP_OPTIMIZELY_ID as string }}
    >
      <BrowserRouter>
        <App data={MileagePlanData} />
      </BrowserRouter>
    </OptimizelyProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
