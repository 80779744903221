import { useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import initialState, { AccountStateType } from "../state/InitialState";
import { appInsights } from "../services/AppInsights";
import axios from "axios";
import { ASLoginUrl } from "../helpers/constants";

let MPChoiceServiceURL = process.env.REACT_APP_MPPERKSSERVICE_ENDPOINT;

type ActionType =
  | { type: "setTokenValue"; value: (typeof initialState)["Token"] }
  | {
      type: "setMileagePlanDetails";
      value: (typeof initialState)["MileagePlanDetails"];
    }
  | { type: "setSuperUserFlag"; value: (typeof initialState)["isSuperUser"] }
  | { type: "setError"; value: (typeof initialState)["Error"] };

const reducer = (
  state: AccountStateType,
  action: ActionType
): AccountStateType => {
  switch (action.type) {
    case "setTokenValue":
      return {
        ...state,
        Token: {
          ...action.value,
        },
      };
    case "setMileagePlanDetails":
      return {
        ...state,
        MileagePlanDetails: {
          ...action.value,
        },
      };
    case "setSuperUserFlag":
      return {
        ...state,
        isSuperUser: action.value,
      };
    case "setError":
      return {
        ...state,
        Error: action.value,
      };
    default:
      return state;
  }
};

const MPChoiceService = () => {
  let [accountState, accountDataDispatch] = useReducer(reducer, initialState);

  // Get account guid data from token
  let fetchDataFromToken = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_ALASKA_TOKEN_URL as string
      );
      parseTokenAndRedirect(data);
    } catch (ex: any) {
      appInsights?.trackException({ exception: ex });
      accountDataDispatch({
        type: "setError",
        value: new Error("Unable to retrieve your Account Information.") as any,
      });
    }
  };

  const redirect = (path: string) => {
    window.location.href = path;
  };

  const parseTokenAndRedirect = (result: any) => {
    const { loggedIn, token, accountGuid } = result;

    if (!loggedIn && process.env.REACT_APP_AUTHENTICATION === "true") {
      redirect(ASLoginUrl + window.location.pathname);
      return;
    }

    if (process.env.REACT_APP_RELEASE_ENV === "local") {
      console.log("Skipping token validation in local environment");
    } else {
      const decodedToken: any = decodeTokenAndFetchData(token);
      const nowPlus10Seconds: any = Date.now() / 1000 + 10; // +10 for network timeout
      // Redirect if token has expired.
      if (decodedToken.exp < nowPlus10Seconds) {
        redirect(
          ASLoginUrl + window.location.pathname + "&advise=eSessionTimeout"
        );
      }
    }

    accountDataDispatch({
      type: "setTokenValue",
      value: { Value: token, Guid: accountGuid },
    });
  };

  const decodeTokenAndFetchData = (token: string): any => {
    return jwtDecode(token);
  };

  const getMPChoiceService = async () => {
    if (accountState?.Token?.Guid && accountState?.Token?.Value) {
      try {
        const MPChoiceServiceURLEndpoint = `${MPChoiceServiceURL}?accountGuid=${accountState.Token.Guid}`;
        const { data } = await axios.get(MPChoiceServiceURLEndpoint, {
          headers: {
            Authorization: `Bearer ${accountState.Token.Value}`,
            "Ocp-Apim-Subscription-Key": process.env
              .REACT_APP_MPCHOICE_BFF_KEY as string,
          },
        });

        const MileagePlanData = data;
        appInsights?.trackEvent({
          name:
            "getMPChoiceService service called for MP# " +
            MileagePlanData.MileagePlanNumber,
        });
        const accountDataDetails: AccountStateType["MileagePlanDetails"] = {
          MileagePlanNumber: MileagePlanData.mileagePlanNumber,
          FirstName: MileagePlanData.firstName,
          LastName: MileagePlanData.lastName,
          TierName: MileagePlanData.tierName,
          MemberBalance: +MileagePlanData.miles,
          ChoiceSelectedStatus: MileagePlanData.mP100KChoice,
          TierEndDate: MileagePlanData.tierEndDate,
          Email: MileagePlanData.email,
          HowTier: MileagePlanData.howTier,
          milestoneId: MileagePlanData.milestoneId,
          miles: MileagePlanData.organicEQMs,
          MemberSelectionId: MileagePlanData.memberSelectionId,
          MilestoneId: MileagePlanData.milestoneId,
          MilestoneName: MileagePlanData.milestoneName,
          MP100KChoice: MileagePlanData.mP100KChoice,
          MilestoneAchievmentDate: MileagePlanData.milestoneAchievmentDate,
          SelectionExpirationDate: MileagePlanData.selectionExpirationDate,
          IsEligibleFor100KChoiceSelection:
            MileagePlanData.isEligibleFor100KChoiceSelection,
          Perks: MileagePlanData.Perks,
        };
        accountDataDispatch({
          type: "setMileagePlanDetails",
          value: accountDataDetails,
        });
      } catch (ex: any) {
        appInsights?.trackException({ exception: ex });
        accountDataDispatch({
          type: "setError",
          value: new Error(
            "Unable to retrieve your Mileage plan Information."
          ) as any,
        });
      }

      // Useful to know if logged in during development
      if (
        process.env.REACT_APP_RELEASE_ENV === "dev" ||
        process.env.REACT_APP_RELEASE_ENV === "test" ||
        process.env.REACT_APP_RELEASE_ENV === "qa"
      ) {
        console.log("Logged in to as.com :-)");
      }

      // Useful to get login prompt during development
    } else if (
      process.env.REACT_APP_RELEASE_ENV === "dev" ||
      process.env.REACT_APP_RELEASE_ENV === "test" ||
      process.env.REACT_APP_RELEASE_ENV === "qa"
    ) {
      console.log("Did you sign in to as.com?");
    }
  };

  useEffect(() => {
    fetchDataFromToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getMPChoiceService();
    // eslint-disable-next-line
  }, [accountState.Token]);

  return {
    accountState,
    accountDataDispatch,
  };
};

export default MPChoiceService;
