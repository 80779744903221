import { useContext } from "react";
import cxs from "cxs";
import WindowIcon, { WindowIconProps } from "../../WindowIcon";
import { ContentContext } from "../../../context/ContentContext";
import { ContentStateType } from "../../../state/InitialState";
import "./styles.scss";

type PerksTileProps = WindowIconProps & {
  name: string;
  redeemUntilDate: string;
  selected: boolean;
  handleClick: (id: number) => void;
  id: number;
  isButton: boolean;
  type?: "button";
};

const MPPerksTile = (props: PerksTileProps) => {
  const {
    name,
    redeemUntilDate,
    icon,
    category,
    selected,
    handleClick,
    id,
    isButton,
  } = props;

  const { contentState }: { contentState: ContentStateType } =
    useContext(ContentContext);

  const {
    titleColor: selectedTitleColor,
    subtitleColor: selectedSubtitleColor,
    borderColor: selectedBorderColor,
    iconBackgroundColor: selectedIconBackgroundColor,
    iconBorderColor: selectedIconBorderColor,
    iconColor: selectedIconColor,
  } = contentState?.Content[1]?.perkSelection?.perk?.perkSelected || {};

  const {
    titleColor: UnselectedTitleColor,
    subtitleColor: UnselectedSubTitleColor,
    borderColor: UnselectedBorderColor,
    iconBorderColor: UnselectedIconBorderColor,
    iconColor: UnselectedIconColor,
  } = contentState?.Content[1]?.perkSelection?.perk?.perkUnselected || {};

  const styles = {
    title: cxs({
      color: selected
        ? `var(--${selectedTitleColor}, #2E2E2E)`
        : `var(--${UnselectedTitleColor}, #2E2E2E)`,
    }),
    subtitle: cxs({
      color: selected
        ? `var(--${selectedSubtitleColor}, #151515)`
        : `var(--${UnselectedSubTitleColor}, #151515)`,
    }),
    button: cxs({
      backgroundColor: selected ? "white" : "transparent",
      borderColor: selected
        ? `var(--${selectedBorderColor}, #D4D4D4)`
        : `var(--${UnselectedBorderColor}, #D4D4D4)`,
    }),
    iconContainer: cxs({
      color: selected
        ? `var(--${selectedIconColor}, #03314F)`
        : `var(--${UnselectedIconColor}, #03314F)`,
      borderColor: selected
        ? `var(--${selectedIconBorderColor}, #2CD7E1)`
        : `var(--${UnselectedIconBorderColor}, #03314F)`,
      backgroundColor: selected
        ? `var(--${selectedIconBackgroundColor}, #2CD7E1)`
        : "",
    }),
  };

  const handleTileClick = () => handleClick(id);

  const getButtonClassName = (isButton: boolean) => {
    let className = "perksTile__container ";
    if (isButton) {
      className += `buttonType ${styles.button}`;
    }
    return className;
  };

  const buttonClass = getButtonClassName(isButton);

  return (
    <button className={buttonClass} onClick={handleTileClick}>
      <WindowIcon
        icon={icon}
        category={category}
        variant={!selected ? "outline" : "solid"}
        iconContainerClassName={styles.iconContainer}
      />
      <div className="perksTile__textContainer">
        <div className={`perksTile__name ${styles.title}`}>{name}</div>
        <div className={`perksTile__date ${styles.subtitle}`}>
          {redeemUntilDate}
        </div>
      </div>
    </button>
  );
};

export default MPPerksTile;
